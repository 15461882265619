(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/mybets/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/widgets/mybets/assets/javascripts/init.js');
"use strict";


svs.widget = svs.widget || {};
svs.widget.mybets = svs.widget.mybets || {};
svs.widget.mybets.init = function () {
  const allWidgets = document.querySelectorAll('.js-mybets-widget-container');
  allWidgets.forEach(widgetEl => {
    if (widgetEl.dataset.init === 'done') {
      return;
    }
    widgetEl.dataset.init = 'done';
    const mybetsElements = widgetEl.querySelectorAll('.js-mybets-item');
    const mybetsButton = widgetEl.querySelector('.js-mybets-btn');
    const correctAllButton = widgetEl.querySelector('.js-mybets-correct-all-btn');
    mybetsElements.forEach(el => {
      el.addEventListener('click', svs.widget.mybets.handleClickItem);
    });
    if (mybetsButton) {
      mybetsButton.addEventListener('click', svs.widget.mybets.handleClickButton);
    }
    if (correctAllButton) {
      correctAllButton.addEventListener('click', svs.widget.mybets.handleClickCorrectAllButton);
    }
  });
};

 })(window);