(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/mybets/assets/javascripts/mybets.js') >= 0) return;  svs.modules.push('/widgets/mybets/assets/javascripts/mybets.js');
"use strict";


svs.widget = svs.widget || {};
svs.widget.mybets = svs.widget.mybets || {};
const logger = svs.core.log.getLogger('widgets:my_bets');
const correctAllBtn = document.querySelector('.js-mybets-correct-all-btn');
const allCorrectedSection = document.querySelector('.js-mybets-all-corrected');
const ongoingHolderSection = document.querySelector('.js-mybets-widget-ongoing-holder');
const handleClickItem = clickEvent => {
  clickEvent.preventDefault();
  const clickedEl = clickEvent.currentTarget;
  const url = clickedEl.getAttribute('href');
  const {
    productName,
    sectionName
  } = clickedEl.children[0].dataset;
  const event = {
    action: sectionName,
    category: 'Personliga rekommendationer',
    opt_label: productName
  };
  svs.components.analytics.trackEvent(event, () => {
    location.href = url;
  });
};
const correctWagers = async () => {
  const customerBetExaminedPromise = svs.core.jupiter.callAsync({
    method: 'POST',
    path: '/customerbet/1/wager/examineall?product=all'
  });
  const wagerExaminedPromise = svs.core.jupiter.callAsync({
    method: 'POST',
    path: '/player/1/examineallwagers'
  });
  const [customerBetExaminedResponse, wagerExaminedResponse] = await Promise.all([customerBetExaminedPromise, wagerExaminedPromise]);
  return {
    customerBetExaminedResponse,
    wagerExaminedResponse
  };
};
const handleClickCorrectAllButton = async () => {
  correctAllBtn.disabled = true;
  try {
    const {
      customerBetExaminedResponse,
      wagerExaminedResponse
    } = await correctWagers();
    if (customerBetExaminedResponse.error) {
      logger.info("/customerbet/1/wager/examineall failed, error code: ".concat(customerBetExaminedResponse.error.code, " message: ").concat(customerBetExaminedResponse.error.message));
    }
    if (wagerExaminedResponse.error) {
      logger.info("/player/1/examineallwager failed, error code: ".concat(wagerExaminedResponse.error.code, " message: ").concat(wagerExaminedResponse.error.message));
    }
    ongoingHolderSection.style.display = 'none';
    allCorrectedSection.style.display = 'block';
  } catch (err) {
    correctAllBtn.disabled = false;
    logger.info("examine wagers failed, error code: ".concat(err.code, " message: ").concat(err.message));
    svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
      actions: [{
        title: 'Okej'
      }],
      area: svs.ui.dialog.area.POPUP,
      branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
      message: [{
        text: 'Ett fel inträffade när dina spel skulle rättas, försök igen senare.'
      }],
      title: 'Något gick visst fel',
      type: svs.ui.dialog.type.DEFAULT
    }));
  }
};
const handleClickButton = clickEvent => {
  clickEvent.preventDefault();
  const clickedEl = clickEvent.currentTarget;
  const url = clickedEl.getAttribute('href');
  const event = {
    action: 'Active_bets',
    category: 'Personliga rekommendationer',
    opt_label: 'Mina spel'
  };
  svs.components.analytics.trackEvent(event, () => {
    location.href = url;
  });
};
setGlobal('svs.widget.mybets', {
  handleClickCorrectAllButton,
  handleClickItem,
  handleClickButton
});

 })(window);